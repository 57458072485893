
	import Header from "../Commons/Header.vue";
	import Vue from "vue";

	export default Vue.extend({
		name: "Campaigns",
		props: {},
		components: { Header },
		data: () => ({}),
		created() {},
		async mounted() {},
		computed: {},
		methods: {},
	});
